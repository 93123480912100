// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/Hamburger.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/Hamburger.tsx");
  import.meta.hot.lastModified = "1724095960744.6023";
}
// REMIX HMR END

const Hamburger = ({
  onClick,
  isActive
}) => <button onClick={onClick} title="Toggle Nav" className={`relative block h-[21px] w-[28px] before:absolute before:left-0 before:top-0 before:h-[3px] before:w-[25px] before:origin-top-left before:rounded-[10px] before:bg-white before:shadow-[0_9px_0_#ffffff] before:content-[''] after:absolute after:bottom-0 after:left-0 after:h-[3px] after:w-[25px] after:origin-bottom-left after:rounded-[10px] after:bg-white after:content-[''] ${isActive ? `before:left-[6px] before:w-[26.5px] before:rotate-45 before:shadow-none after:left-[6px] after:w-[26.5px] after:-rotate-45` : ""} md:hidden`} />;
_c = Hamburger;
export default Hamburger;
var _c;
$RefreshReg$(_c, "Hamburger");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;