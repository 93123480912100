// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/Search.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/Search.tsx");
  import.meta.hot.lastModified = "1727460864853.2036";
}
// REMIX HMR END

import React from "react";
const Search = () => {
  _s();
  const [bounced, setBounced] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);
  const [results, setResults] = React.useState([]);
  const handleInputChange = async event => {
    setValue(event.target.value);
    if (bounced || event.target.value.length < 1) {
      setResults([]);
      setIsActive(false);
      return;
    }
    const url = new URL(`${window.location.origin}/resources/search`);
    url.searchParams.set("query", event.target.value);
    try {
      const request = await fetch(url);
      if (!request.ok) {
        setBounced(true);
        setResults([]);
        setIsActive(false);
        return;
      }
      const response = await request.json();
      if (response.length < 1) {
        setResults([]);
        setIsActive(false);
        return;
      }
      setResults(response);
      setIsActive(true);
    } catch (error) {
      console.error(error);
      setResults([]);
      setIsActive(false);
    }
  };
  const handleSearchResultsLinkClick = productName => {
    setValue(productName);
    setIsActive(false);
  };
  const handleBlur = event => {
    const resultNames = results.map(({
      name
    }) => name);
    const targetValue = event.nativeEvent.relatedTarget?.textContent;
    if (!targetValue || !resultNames.includes(targetValue)) {
      setIsActive(false);
      return;
    }
  };
  return <form className="relative order-4 flex w-full flex-wrap self-start md:order-none md:w-[50vw] md:max-w-[53%]" method="get" action="/search" onBlur={handleBlur}>
      <input name="search" onChange={handleInputChange} onFocus={() => results.length > 0 && setIsActive(true)} type="search" value={value} placeholder="Search by brand, color, style and more." className="h-[2.1875rem] w-[calc(100%-4.5rem)] grow rounded-l-[3px] border border-r-0 border-white pl-[0.4375rem] placeholder-black md:border-black" />
      {isActive && <ul className="absolute top-[calc(100%+0.25rem)] z-[99] w-[calc(100%-4.5rem)] border border-black bg-white">
          {results.map((product, idx) => <li key={`search-result-${product.id}-${idx}`} className="w-full p-1.5">
              <a tabIndex={0} className="block w-full overflow-hidden text-ellipsis whitespace-nowrap text-blue400" onClick={() => handleSearchResultsLinkClick(product.name)} href={product.path}>
                {product.name}
              </a>
            </li>)}
        </ul>}
      <button type="submit" aria-label="Search" className="center flex w-[4.5rem] min-w-[4.5rem] items-center justify-center rounded-r-[3px] bg-[#22c37f]" title="Search">
        <svg width="20" height="20" className="h-5 w-5 fill-white stroke-white">
          <path d="M18.32,18.32c-.22,.22-.44,.29-.66,.29s-.51-.15-.66-.29l-4.89-4.89c-1.24,1.02-2.85,1.61-4.6,1.61C3.36,15.04,0,11.68,0,7.52S3.28,0,7.45,0s7.52,3.36,7.52,7.52c0,1.75-.58,3.36-1.61,4.6l4.89,4.89c.36,.36,.36,.88,.07,1.31ZM7.45,13.21c3.14,0,5.69-2.55,5.69-5.69S10.58,1.82,7.45,1.82,1.75,4.38,1.75,7.52s2.55,5.69,5.69,5.69Z" />
        </svg>
      </button>
    </form>;
};
_s(Search, "WZM2Lcm0EbscJ5cO+LWOh5oZoGo=");
_c = Search;
export default Search;
var _c;
$RefreshReg$(_c, "Search");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;