// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/Contacts.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/Contacts.tsx");
  import.meta.hot.lastModified = "1724095960742.7778";
}
// REMIX HMR END

const Contacts = ({
  children
}) => <div className="flex w-full flex-col items-center gap-y-5 md:mx-auto md:max-w-[500px] md:flex-row md:flex-wrap md:gap-y-0 lg:w-[calc(25%)] lg:flex-col">
    {children}
  </div>;
_c = Contacts;
export default Contacts;
var _c;
$RefreshReg$(_c, "Contacts");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;