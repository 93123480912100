// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/hooks/useBreakpoints.ts"
);
import.meta.hot.lastModified = "1724095960753.3457";
}
// REMIX HMR END

import { useWindowSize } from "@react-hook/window-size";
import { useMemo } from "react";
import { useHydrated } from "./useHydrated";

const useBreakpoints = () => {
  const isHydrated = useHydrated();
  const [width] = useWindowSize({
    initialWidth: 0,
  });

  const isDesktop = useMemo(() => {
    return isHydrated && width >= 768;
  }, [isHydrated, width]);

  const isMobile = useMemo(() => {
    return isHydrated && width < 768;
  }, [isHydrated, width]);

  return { isMobile, isDesktop };
};

export default useBreakpoints;
