// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/PaymentProviders.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/PaymentProviders.tsx");
  import.meta.hot.lastModified = "1724095960743.4958";
}
// REMIX HMR END

const PaymentProvider = ({
  provider
}) => {
  const bgPosition = {
    paypal: "-248px",
    visa: "-65px",
    amex: "0",
    discover: "-124px",
    mastercard: "-186px",
    apple: "0",
    google: "0"
  }[provider];
  const bgImage = {
    paypal: "//cdn.shirtspace.com/assets/accepted-credit-cards-f0e3008245b35ebb135323ef67ffa99d48339159d83671ea11c6db6b51671a35.png",
    visa: "//cdn.shirtspace.com/assets/accepted-credit-cards-f0e3008245b35ebb135323ef67ffa99d48339159d83671ea11c6db6b51671a35.png",
    amex: "//cdn.shirtspace.com/assets/accepted-credit-cards-f0e3008245b35ebb135323ef67ffa99d48339159d83671ea11c6db6b51671a35.png",
    discover: "//cdn.shirtspace.com/assets/accepted-credit-cards-f0e3008245b35ebb135323ef67ffa99d48339159d83671ea11c6db6b51671a35.png",
    mastercard: "//cdn.shirtspace.com/assets/accepted-credit-cards-f0e3008245b35ebb135323ef67ffa99d48339159d83671ea11c6db6b51671a35.png",
    apple: "//cdn.shirtspace.com/assets/apple-pay-logo-43e426098e886939149be722633b15e33056bd9466983ab915bf443e1990b480.jpg",
    google: "//cdn.shirtspace.com/assets/google-pay-logo-5588d16243c0560cd6df5ebbda6b1409fc6d9cb6612ef76a49bd6410091a1cbc.png"
  }[provider];
  const label = {
    paypal: "PayPal",
    visa: "Visa",
    amex: "American Express",
    discover: "Discover",
    mastercard: "MasterCard",
    apple: "Apple Pay",
    google: "Google Pay"
  }[provider];
  return <li className="h-[37px] w-[57px] scale-[0.8] bg-no-repeat text-[0]" style={{
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: bgPosition
  }}>
      {label}
    </li>;
};
_c = PaymentProvider;
const PaymentProviders = () => <ul className="flex flex-wrap items-center justify-center gap-1 py-5">
    <PaymentProvider provider="paypal" />
    <PaymentProvider provider="visa" />
    <PaymentProvider provider="amex" />
    <PaymentProvider provider="discover" />
    <PaymentProvider provider="mastercard" />
    <PaymentProvider provider="apple" />
    <PaymentProvider provider="google" />
    <li className="mt-1 h-10">
      <a href="https://www.bbb.org/us/wa/ridgefield/profile/embroidery/all-about-apparel-inc-1296-22538232#bbbsea" rel="noreferrer" target="_blank" title="All About Apparel Inc, Embroidery, Ridgefield, WA">
        <img className="max-h-full" alt="" src="https://cdn.shirtspace.com/assets/all-about-apparel-0e6bed71ee1e3869c69045613e99da0bbcbeacf2c5e44abae2960d3789c65fc4.png" />
      </a>
    </li>
    <li className="h-10">
      <a target="_blank" rel="noreferrer" href="//instantssl.com/ssl.html">
        <img alt="" className="max-h-full" src="https://cdn.shirtspace.com/assets/comodo-secure-da6ca3d0d0432b038a11fa21ae2e0b62fb50a85964b665e83e8c00162e4e4a73.png" />
      </a>
    </li>
  </ul>;
_c2 = PaymentProviders;
export default PaymentProviders;
var _c, _c2;
$RefreshReg$(_c, "PaymentProvider");
$RefreshReg$(_c2, "PaymentProviders");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;