// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/MenuItem.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/MenuItem.tsx");
  import.meta.hot.lastModified = "1728406550621.2048";
}
// REMIX HMR END

import React from "react";
import DownArrow from "./DownArrow";
import useBreakpoints from "~/hooks/useBreakpoints";
export const MenuBrandItem = ({
  children
}) => <li className="md:gro block h-[80px] max-h-[calc(max(85px,12vh))] w-1/2 max-w-[250px] md:flex md:h-auto md:w-[8.125rem] ">
    {children}
  </li>;
_c = MenuBrandItem;
export const MenuBrandViewAll = () => <a href="/brands" className="mx-auto flex w-full items-center justify-center gap-2 underline md:mt-4 md:h-12 md:text-black">
    View All <DownArrow />
  </a>;
_c2 = MenuBrandViewAll;
export const MenuLink = ({
  href,
  children
}) => <a href={href} className="text-2xl md:block md:w-full md:px-1 md:py-1 md:text-sm md:text-black md:hover:bg-blue md:hover:text-white">
    {children}
  </a>;
_c3 = MenuLink;
export const MenuBrandLink = ({
  href,
  children
}) => <a href={href} className="relative flex h-full items-center justify-center md:h-[80px] md:w-[250px]">
    {children}
  </a>;
_c4 = MenuBrandLink;
export const MenuBrandLogo = ({
  src,
  alt
}) => {
  _s();
  const {
    isMobile
  } = useBreakpoints();
  const [isHover, setIsHover] = React.useState(false);
  if (isMobile) return <img className="absolute max-h-[85%] max-w-[85%]" alt={alt} src={src.white} />;
  return <img onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} className="absolute max-h-[85%] max-w-[85%]" alt={alt} src={isHover ? src.color : src.gray} />;
};
_s(MenuBrandLogo, "Fc3QD7JpHtvywZXM6n9kZ0nkSLc=", false, function () {
  return [useBreakpoints];
});
_c5 = MenuBrandLogo;
const MenuItem = ({
  children
}) => <li className="md:p-1">{children}</li>;
_c6 = MenuItem;
export default MenuItem;
var _c, _c2, _c3, _c4, _c5, _c6;
$RefreshReg$(_c, "MenuBrandItem");
$RefreshReg$(_c2, "MenuBrandViewAll");
$RefreshReg$(_c3, "MenuLink");
$RefreshReg$(_c4, "MenuBrandLink");
$RefreshReg$(_c5, "MenuBrandLogo");
$RefreshReg$(_c6, "MenuItem");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;