// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/DownArrow.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/DownArrow.tsx");
  import.meta.hot.lastModified = "1724095960744.4958";
}
// REMIX HMR END

const DownArrow = () => <svg width="10" height="10" id="arrow-down" viewBox="0 0 18.23 11.18" className="fill-white md:fill-black">
    <path d="M17.53,3.79c.88-.77,.93-2.14,.16-3.01s-2.14-.93-3.01-.16l-5.53,4.87L3.57,.56C2.69-.26,1.32-.15,.56,.72-.26,1.6-.15,2.97,.72,3.73l8.43,7.45L17.53,3.79Z" />
  </svg>;
_c = DownArrow;
export default DownArrow;
var _c;
$RefreshReg$(_c, "DownArrow");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;