// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/Nav.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/Nav.tsx");
  import.meta.hot.lastModified = "1728406550621.6348";
}
// REMIX HMR END

import useBreakpoints from "~/hooks/useBreakpoints";
export const NavControls = ({
  children
}) => <div className="flex items-center justify-between md:hidden">{children}</div>;
_c = NavControls;
export const NavBackControl = ({
  onClick
}) => <button onClick={onClick} className="flex items-center gap-1 md:hidden" aria-labelledby="navBackControlLabel">
    <svg viewBox="0 0 21.82 19.92" className="h-4 w-4 fill-white">
      <path d="M0,8.77c.32,.96,.96,1.27,1.91,1.27h3.66c.48,0,.96,0,1.43,0,.8-.16,1.27-.8,1.27-1.59,0-.64-.64-1.27-1.43-1.27h-.48c-.32,0-.32-.16-.32-.48,.16-.16,.16-.32,.32-.48,1.59-2.07,3.66-3.03,6.21-2.71,3.66,.48,6.21,4.14,5.42,7.65-.64,3.19-3.19,5.26-6.37,5.26-.96,.16-1.75,.8-1.75,1.75s.8,1.75,1.75,1.75,1.75-.16,2.87-.32c4.78-1.27,8.12-6.21,7.17-11.31C20.86,3.84,17.04,.33,12.58,.01,9.08-.15,6.21,1.13,3.98,3.84l-.48,.48c-.16-.32-.16-.64-.16-.8-.16-.96-.96-1.59-1.91-1.43-.64,.16-.96,.64-1.11,1.11,0,1.91,0,3.82-.32,5.57Z" />
    </svg>
    <span id="navBackControlLabel" className="text-sm uppercase text-white md:hidden">
      Go back
    </span>
  </button>;
_c2 = NavBackControl;
export const NavCloseControl = ({
  onClick
}) => <button onClick={onClick} className="ml-auto flex items-center gap-1 md:hidden" aria-labelledby="navCloseControlLabel">
    <svg viewBox="0 0 21.46 19.34" className="h-4 w-4 fill-white">
      <path d="M8.16,9.97L.6,19.04H5.14l3.32-4.23c.91-1.21,1.51-2.12,2.42-3.32h.3c.6,1.51,1.51,2.42,2.12,3.63l3.02,4.23h4.53l-7.56-9.67L21.46,0h-4.53l-3.63,4.53c-.91,1.21-1.81,2.42-2.72,3.32h-.3c-.91-.91-1.51-2.12-2.42-3.32L4.53,0H0L8.16,9.97Z" />
    </svg>
    <span id="navCloseControlLabel" className="text-sm uppercase text-white md:hidden">
      Close
    </span>
  </button>;
_c3 = NavCloseControl;
const Nav = ({
  isActive,
  children
}) => {
  _s();
  const {
    isDesktop
  } = useBreakpoints();
  const hidden = !isDesktop && !isActive;
  return <nav aria-hidden={hidden} className={`${hidden ? "hidden" : "block"} fixed bottom-0 left-0 top-[3.3125rem] z-50 ml-0 h-auto w-full max-w-[55ch] gap-3.5 overflow-scroll border-t border-t-white bg-blue pl-7 pr-5 pt-5 before:absolute before:left-[1rem] before:top-[-94px] before:mt-[75px] before:border-[12px] before:border-b-[6px] before:border-solid before:border-[transparent_transparent_white_transparent] before:content-[''] after:absolute after:left-[1rem] after:top-[-93px] after:mt-[75px] after:border-[12px] after:border-b-[6px] after:border-solid after:border-[transparent_transparent_#047ACA_transparent] after:content-[''] md:relative md:top-0 md:z-0 md:block md:max-w-none md:overflow-visible md:border-t md:border-t-black md:bg-white md:px-0 md:pl-0.5 md:pt-2 md:text-black md:before:hidden md:after:hidden lg:ml-[13.25rem]`}>
      <ul className={`flex flex-col gap-1.5 text-white md:flex-row md:gap-0`}>
        {children}
      </ul>
    </nav>;
};
_s(Nav, "5NUdrMHE6FfmIwRycm/+4+A42jg=", false, function () {
  return [useBreakpoints];
});
_c4 = Nav;
export default Nav;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "NavControls");
$RefreshReg$(_c2, "NavBackControl");
$RefreshReg$(_c3, "NavCloseControl");
$RefreshReg$(_c4, "Nav");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;