// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/AddressPhone.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/AddressPhone.tsx");
  import.meta.hot.lastModified = "1724095960742.5212";
}
// REMIX HMR END

const AddressPhone = () => <menu className="flex flex-col gap-y-1 md:w-1/2 lg:mb-8 lg:w-full">
    <li className="text-center">
      <a className="text-3xl text-blue hover:text-blue500" href="tel:8772857606">
        (877) 285-7606
      </a>
    </li>
    <li className="text-black100 text-nowrap text-center">
      Mon–Fri, 6:30 AM–4:00 PM PST
    </li>
    <li className="text-black100 text-nowrap text-center">
      7509 S. 5th St. Ridgefield, WA 98642
    </li>
  </menu>;
_c = AddressPhone;
export default AddressPhone;
var _c;
$RefreshReg$(_c, "AddressPhone");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;