// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/SignIn.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/SignIn.tsx");
  import.meta.hot.lastModified = "1727460864853.5444";
}
// REMIX HMR END

import React from "react";
import DownArrow from "./DownArrow";
import { IconContainer, IconDropdown, IconLabel, IconSVG } from "./Icon";
import useBreakpoints from "~/hooks/useBreakpoints";
import Modal from "../Modal";
import CreateAccount from "./CreateAccount";
import { useLocation, useNavigate } from "@remix-run/react";
import FlashMessage from "../FlashMessage";
const ErrorMessage = () => <FlashMessage kind="failure" message="Invalid email or password" />;
_c = ErrorMessage;
export const DropdownContents = () => {
  _s();
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const form = new FormData(event.currentTarget);
      const request = await fetch("/resources/signIn", {
        method: "post",
        body: form
      });
      if (!request.ok) {
        throw new Error("Invalid username or password");
      }
      return navigate(location.pathname, {
        state: {
          flash: {
            kind: "success",
            message: "You have successfully signed in"
          }
        }
      });
    } catch (error) {
      setIsError(true);
      setPassword("");
    }
  };
  const handleInputChange = event => {
    setIsError(false);
    if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  };
  return <form onSubmit={handleSubmit} className="flex flex-col gap-3.5 px-7 pt-3">
      {isError ? <ErrorMessage /> : null}
      <div className="flex flex-col gap-1">
        <label className="text-sm text-blue900" htmlFor="email">
          Email
        </label>
        <input id="email" className="h-8 rounded border border-black p-2" type="text" name="email" value={email} onChange={handleInputChange} placeholder="Email" />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-sm text-blue900" htmlFor="password">
          Password
        </label>
        <input id="password" className="h-8 rounded border border-black p-2" type="password" name="password" onChange={handleInputChange} value={password} placeholder="Password" />
      </div>
      <div className="mb-1 flex justify-between pr-4">
        <div className="flex flex-row-reverse items-center gap-1">
          <label className="text-sm text-blue900" htmlFor="remember">
            Remember me
          </label>
          <input id="remember" type="checkbox" name="remember" />
        </div>
        <a className="text-sm text-blue400 underline" href="/users/password/new">
          Forgot password
        </a>
      </div>
      <p className="mb-3 text-gray">
        By signing into your account, you consent to ShirtSpace&apos;s{" "}
        <a className="text-sm text-blue900 underline" href="/privacy-policy">
          Privacy Policy
        </a>
        .
      </p>
      <div className="align-center flex gap-3">
        <button type="submit" className="h-8 w-auto self-start rounded-md bg-blue px-4  text-lg uppercase text-white hover:bg-blue200">
          Sign In
        </button>
        <p className="self-center text-sm text-gray">
          <span>Or</span>{" "}
          <a href="/users/sign_up" className="text-blue900 underline">
            Create an account
          </a>
        </p>
      </div>
    </form>;
};
_s(DropdownContents, "HhGYuIoIbMb5oL+a78QIa+tr7us=", false, function () {
  return [useLocation, useNavigate];
});
_c2 = DropdownContents;
const SignIn = ({
  hasSession
}) => {
  _s2();
  const {
    isMobile,
    isDesktop
  } = useBreakpoints();
  const [isDropdownActive, setIsDropdownActive] = React.useState(false);
  const [isCreateAccountActive, setIsCreateAccountActive] = React.useState(false);
  React.useEffect(() => {
    if (!isDropdownActive || !isCreateAccountActive) return;
    setIsDropdownActive(false);
  }, [isDropdownActive, isCreateAccountActive]);
  const handleMouseEnter = () => {
    if (isMobile) return;
    setIsDropdownActive(true);
  };
  const handleMouseLeave = event => {
    if (isMobile) return;
    if (!(event.target instanceof HTMLElement)) {
      setIsDropdownActive(false);
      return;
    }

    /*
     * If the mouse is still within the bounds of the dropdown, do nothing.
     * This is necessary because the mouseleave event may be in some cases
     * triggered by an element injected by a browser extension
     * (e.g. password managers)
     */
    const isMenu = event.target.closest("[role=menu]");
    if (!isMenu) {
      setIsDropdownActive(false);
      return;
    }
    const element = isMenu;
    const {
      top,
      bottom,
      left,
      right
    } = element.getBoundingClientRect();
    const {
      x,
      y
    } = event.nativeEvent;
    if (x >= left && x <= right && y >= top && y <= bottom) return;
    setIsDropdownActive(false);
  };
  const handleClick = event => {
    if (isDesktop) {
      window.location.href = hasSession ? "/account" : "/users/sign_in";
      return;
    } else if (isMobile && hasSession) {
      window.location.href = "/account";
      return;
    }
    event.preventDefault();
    setIsDropdownActive(!isDropdownActive);
  };
  const handleCloseClick = event => {
    event.preventDefault();
    setIsDropdownActive(false);
  };
  return <IconContainer href={hasSession ? "/account" : "/users/sign_in"} title={hasSession ? "Account" : "Sign In"} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <IconSVG>
        <g>
          <path d="M10.66,10.95c10.43,0,10.66,7.38,10.66,7.38H0s.23-7.38,10.66-7.38Z" />
          <circle cx="10.66" cy="4.5" r="4.5" />
        </g>
      </IconSVG>
      <IconLabel>
        {hasSession ? "Account" : "Sign In"}
        {hasSession ? null : <DownArrow />}
      </IconLabel>
      <IconDropdown isActive={!hasSession && isDropdownActive} onCloseClick={handleCloseClick}>
        <DropdownContents />
      </IconDropdown>
      <Modal isOpen={isCreateAccountActive} setIsOpen={setIsCreateAccountActive}>
        <CreateAccount />
      </Modal>
    </IconContainer>;
};
_s2(SignIn, "ZdAtv5vnDqaG7RxuMYFoY8qzIqg=", false, function () {
  return [useBreakpoints];
});
_c3 = SignIn;
export default SignIn;
var _c, _c2, _c3;
$RefreshReg$(_c, "ErrorMessage");
$RefreshReg$(_c2, "DropdownContents");
$RefreshReg$(_c3, "SignIn");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;