// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/Bottom.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/Bottom.tsx");
  import.meta.hot.lastModified = "1724095960742.6396";
}
// REMIX HMR END

const Bottom = () => <div className="bg-blue px-2.5 py-5">
    <div className="md-gap-y-0 mx-auto flex w-full max-w-[1070px] flex-col items-center justify-center gap-y-5 md:flex-row md:items-end md:justify-between">
      <a href="https://adasitecompliancetools.com/ap.asp?h=SHRSPC" target="_blank" rel="noreferrer">
        <img alt="ADA Site Compliance-Accessibility Policy" height="82" loading="lazy" src="//www.adasitecompliance.com/img/APolicy.png" width="150" />
      </a>
      <span className="text-sm text-white">
        &copy; 2024 ShirtSpace All Rights Reserved.
      </span>
    </div>
  </div>;
_c = Bottom;
export default Bottom;
var _c;
$RefreshReg$(_c, "Bottom");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;