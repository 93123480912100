// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/Social.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/Social.tsx");
  import.meta.hot.lastModified = "1724095960743.6575";
}
// REMIX HMR END

const SocialIcon = ({
  viewBox,
  children
}) => <svg className="w-8 fill-blue hover:fill-blue500" viewBox={viewBox}>
    {children}
  </svg>;
_c = SocialIcon;
const Social = () => <nav className="flex items-center md:w-1/2 lg:w-full">
    <ul className="flex flex-wrap items-center justify-center gap-x-2.5">
      <li className="w-full text-center">
        <a className="text-[18px] text-blue" href="/community" target="_blank" rel="noreferrer">
          Join The Conversation
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/ShirtSpaceTees/" target="_blank" rel="noreferrer" aria-label="Facebook">
          <SocialIcon viewBox="0 0 10.65 10.65">
            <path d="M8.35,10.65c1.28,0,2.3-1.02,2.3-2.3V2.3c0-1.28-1.02-2.3-2.3-2.3H2.3C1.02,0,0,1.02,0,2.3v6.05c0,1.28,1.02,2.3,2.3,2.3h2.14v-3.48h-1.23v-1.44h1.23v-.11h0v-1.12c0-.37,.05-.7,.27-1.02s.53-.59,.91-.7c.27-.05,.48-.11,.7-.11,.37,0,.75,.05,1.12,.11h0v1.23h-.64c-.11,0-.27,0-.37,.05-.21,.11-.32,.32-.37,.53v1.12h1.39c-.05,.48-.16,.96-.21,1.44h-1.12v3.48h2.25Z" />
          </SocialIcon>
        </a>
      </li>
      <li>
        <a href="https://twitter.com/shirtspacetees" target="_blank" rel="noreferrer" aria-label="Twitter">
          <SocialIcon viewBox="0 0 22.9 18.6">
            <g id="icon--twitter">
              <path d="M20.6,4.6v.6c0,7.4-5.9,13.4-13.2,13.4h-.2c-2.6,0-5.1-.7-7.2-2.1,.4,0,.8,.1,1.1,.1,2.1,0,4.2-.7,5.8-2-2,0-3.8-1.4-4.4-3.3,.3,.1,.6,.1,.9,.1,.4,0,.8-.1,1.2-.2-2.2-.4-3.7-2.4-3.7-4.6v-.1c.7,.4,1.4,.6,2.1,.6C1.7,6.2,.9,4.8,.9,3.2c0-.8,.2-1.7,.6-2.4C3.9,3.8,7.4,5.6,11.2,5.8,10.6,3.3,12.2,.7,14.7,.1c.3-.1,.7-.1,1-.1,1.3,0,2.5,.5,3.4,1.5,1.1-.2,2.1-.6,3-1.1-.4,1.1-1.1,2-2.1,2.6,1.2-.2,2.1-.4,2.9-.8-.6,.9-1.4,1.7-2.3,2.4h0Z" />
            </g>
          </SocialIcon>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/shirtspace/" target="_blank" rel="noreferrer" aria-label="Instagram">
          <SocialIcon viewBox="0 0 22.9 22.8">
            <g id="icon--instagram">
              <path d="M11.4,0c-3.1,0-3.5,0-4.7,.1-1,0-1.9,.2-2.8,.5-.8,.3-1.4,.7-2,1.3-.6,.6-1,1.2-1.3,2C.3,4.8,.1,5.7,.1,6.7c0,1.2-.1,1.6-.1,4.7s0,3.5,.1,4.7c0,.9,.2,1.9,.5,2.8,.3,.8,.7,1.4,1.3,2,.6,.6,1.2,1,2,1.3,.9,.3,1.8,.5,2.8,.5,1.2,.1,1.6,.1,4.7,.1s3.5,0,4.7-.1c.9,0,1.9-.2,2.8-.5,.8-.3,1.4-.7,2-1.3s1-1.2,1.3-2c.4-.9,.5-1.8,.6-2.8,.1-1.2,.1-1.6,.1-4.7s0-3.5-.1-4.7c0-.9-.2-1.9-.5-2.8-.3-.8-.7-1.4-1.3-2s-1.2-1-2-1.3c-.9-.3-1.8-.5-2.8-.5-1.3-.1-1.7-.1-4.8-.1m0,2.1c3,0,3.4,0,4.6,.1,.7,0,1.4,.1,2.1,.4,.5,.2,.9,.5,1.3,.9,.4,.4,.7,.8,.8,1.3,.3,.7,.4,1.4,.4,2.1,.1,1.2,.1,1.5,.1,4.6s0,3.4-.1,4.6c0,.7-.1,1.4-.4,2.1-.2,.5-.5,.9-.8,1.3-.4,.4-.8,.7-1.3,.8-.7,.2-1.4,.4-2.1,.4-1.2,0-1.6,.1-4.6,.1s-3.4,0-4.6-.1c-.7,0-1.4-.1-2.1-.4-.5-.2-.9-.5-1.3-.8-.4-.4-.7-.8-.9-1.3-.3-.7-.4-1.4-.4-2.1-.1-1.2-.1-1.5-.1-4.6s0-3.4,.1-4.6c0-.7,.1-1.4,.4-2.1,.2-.5,.5-.9,.9-1.3s.8-.7,1.3-.9c.7-.2,1.4-.4,2.1-.4,1.2-.1,1.6-.1,4.6-.1" />
              <path d="M11.4,15.1c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8,3.8,1.7,3.8,3.8h0c0,2.2-1.7,3.8-3.8,3.8m0-9.6c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8,5.8-2.6,5.8-5.8h0c0-3.2-2.6-5.8-5.8-5.8" />
              <path d="M18.8,5.3c0,.8-.6,1.4-1.4,1.4s-1.4-.6-1.4-1.4,.6-1.4,1.4-1.4,1.4,.6,1.4,1.4h0" />
            </g>
          </SocialIcon>
        </a>
      </li>
      <li>
        <a href="https://www.youtube.com/channel/UCxF_nPMxCsNCAH94S2nm25Q" target="_blank" rel="noreferrer" aria-label="Youtube">
          <SocialIcon viewBox="0 0 22.8 16.1">
            <g id="icon--youtube">
              <path d="M14.4,8.4l-5.4,2.8V4.8l.4,.2,5.7,3-.7,.4ZM19.2,.1s-5.6-.1-7.8-.1h0C9.2,0,3.5,.1,3.5,.1,1.7,.2,.2,1.6,.2,3.5c0,0-.2,2.5-.2,4.5s.2,4.5,.2,4.5c0,1.8,1.5,3.3,3.3,3.4,0,0,4.6,.2,7.9,.2h0c3.3,0,7.8-.2,7.8-.2,1.8,0,3.3-1.5,3.4-3.4,0,0,.2-2.5,.2-4.5s-.2-4.5-.2-4.5c-.1-1.9-1.5-3.3-3.4-3.4" />
            </g>
          </SocialIcon>
        </a>
      </li>
    </ul>
  </nav>;
_c2 = Social;
export default Social;
var _c, _c2;
$RefreshReg$(_c, "SocialIcon");
$RefreshReg$(_c2, "Social");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;