// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/Links.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/Links.tsx");
  import.meta.hot.lastModified = "1724095960743.0144";
}
// REMIX HMR END

const LinksHeader = ({
  children
}) => <h2 className="mb-2.5 font-serif text-xl font-bold text-blue900">
    {children}
  </h2>;
_c = LinksHeader;
const LinksList = ({
  children
}) => <ul className="flex flex-col gap-1 pl-2.5">{children}</ul>;
_c2 = LinksList;
const LinksLink = ({
  children,
  href
}) => <a href={href} className="text-black100 hover:text-blue">
    {children}
  </a>;
_c3 = LinksLink;
export const LinksNav = ({
  header,
  links
}) => <nav>
    <LinksHeader>{header}</LinksHeader>
    <LinksList>
      {links.map(({
      href,
      text
    }) => <li key={href}>
          <LinksLink href={href}>{text}</LinksLink>
        </li>)}
    </LinksList>
  </nav>;
_c4 = LinksNav;
const Links = ({
  children
}) => <div className="flex w-full flex-col justify-between gap-y-5 text-center md:mx-auto md:max-w-[764px] md:flex-row md:gap-y-0 md:text-left lg:mx-0 lg:w-[calc(75%-5rem)] lg:max-w-none">
    {children}
  </div>;
_c5 = Links;
export default Links;
var _c, _c2, _c3, _c4, _c5;
$RefreshReg$(_c, "LinksHeader");
$RefreshReg$(_c2, "LinksList");
$RefreshReg$(_c3, "LinksLink");
$RefreshReg$(_c4, "LinksNav");
$RefreshReg$(_c5, "Links");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;