// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/Icon.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/Icon.tsx");
  import.meta.hot.lastModified = "1727460864852.9312";
}
// REMIX HMR END

import React from "react";
export const IconContainer = ({
  children,
  title,
  onMouseEnter,
  onMouseLeave,
  onClick
}) => <div onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} title={title} role="link" className="relative -mt-0.5 ml-auto flex h-[1.875rem] w-[1.625rem] cursor-pointer flex-col items-center justify-center text-sm last-of-type:ml-1 md:h-[2.875rem] md:w-[4.0625rem] md:last-of-type:ml-0">
    {children}
  </div>;
_c = IconContainer;
export const IconSVG = ({
  children
}) => <svg viewBox="0 0 21 19" className="flex-grow fill-white md:h-28 md:w-32 md:fill-black">
    {children}
  </svg>;
_c2 = IconSVG;
export const IconLabel = ({
  children
}) => <span className="hidden md:flex md:items-center md:gap-1 md:text-[#294f66]">
    {children}
  </span>;
_c3 = IconLabel;
export const IconDropdown = ({
  children,
  onCloseClick,
  isActive
}) => <div role="menu" onClick={event => event.stopPropagation()} className={`${isActive ? "visible" : "invisible"} fixed bottom-0 left-0 top-[3.125rem] z-50 flex h-auto w-full cursor-default flex-col bg-white before:absolute before:right-[0.325rem] before:top-[-93px] before:mt-[70px] before:border-[16px] before:border-b-[8px] before:border-solid before:border-[transparent_transparent_white_transparent] before:content-[''] md:absolute md:left-auto md:right-[10px] md:top-[50px] md:h-[calc(100vh-70px)] md:max-h-[515px] md:w-[385px] md:border md:border-[#494949] md:after:absolute md:after:right-[0.325rem] md:after:top-[-94px] md:after:-z-10 md:after:mt-[70px] md:after:border-[16px] md:after:border-b-[8px] md:after:border-solid md:after:border-[transparent_transparent_black_transparent] md:after:content-['']`}>
    <button onClick={onCloseClick} className="right-0 top-0 ml-auto mr-1 mt-1 flex items-center gap-1 p-2 text-sm text-gray" aria-label="Close">
      <svg className="w-4 fill-gray" viewBox="0 0 21.46 19.34">
        <path d="M8.16,9.97L.6,19.04H5.14l3.32-4.23c.91-1.21,1.51-2.12,2.42-3.32h.3c.6,1.51,1.51,2.42,2.12,3.63l3.02,4.23h4.53l-7.56-9.67L21.46,0h-4.53l-3.63,4.53c-.91,1.21-1.81,2.42-2.72,3.32h-.3c-.91-.91-1.51-2.12-2.42-3.32L4.53,0H0L8.16,9.97Z" />
      </svg>
      CLOSE
    </button>
    {children}
  </div>;
_c4 = IconDropdown;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "IconContainer");
$RefreshReg$(_c2, "IconSVG");
$RefreshReg$(_c3, "IconLabel");
$RefreshReg$(_c4, "IconDropdown");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;