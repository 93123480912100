// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/NewsletterSignup.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/NewsletterSignup.tsx");
  import.meta.hot.lastModified = "1728406550620.1453";
}
// REMIX HMR END

import React from "react";
const NewsletterSignup = () => {
  _s();
  const [error, setError] = React.useState(null);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const handleSubmit = async event => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    const email = form.get("newsletterEmail");
    try {
      const request = await fetch("https://a.klaviyo.com/client/subscriptions/?company_id=Vq4qCb", {
        method: "POST",
        headers: {
          revision: "2024-02-15",
          "content-type": "application/json"
        },
        body: JSON.stringify({
          data: {
            relationships: {
              list: {
                data: {
                  type: "list",
                  id: "U5gNic"
                }
              }
            },
            type: "subscription",
            attributes: {
              profile: {
                data: {
                  type: "profile",
                  attributes: {
                    email
                  }
                }
              }
            }
          }
        })
      });
      if (!request.ok) {
        const response = await request.json();
        throw new Error(response?.errors?.[0]?.detail);
      }
      setIsSuccess(true);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(JSON.stringify(error));
      }
      setError("Please try again.");
    }
  };
  if (isSuccess) {
    return <div className="flex w-full flex-wrap items-start justify-center gap-x-2.5 gap-y-1 bg-white300 py-5">
        <div className="px-5.5 py-2.5 text-center text-lg font-bold text-green100">
          You&apos;re signed up!
        </div>
      </div>;
  }
  return <form onSubmit={handleSubmit} className="flex w-full flex-wrap items-start justify-center gap-x-2.5 gap-y-1 bg-white300 py-5">
      <h4 className="w-full text-center text-sm text-black">
        Sign up to receive news and special offers.
      </h4>
      <div className="w-[200px]">
        <input id="newsletterEmail" name="newsletterEmail" className={`h-9 w-full rounded ${error ? "border border-red100" : "border"} pl-2`} aria-label="Email Address" placeholder="Email Address" type="email" onChange={() => error && setError(null)} onFocus={() => setError(null)} />
        {error ? <p className="mt-1 w-full text-sm text-red100">{error}</p> : null}
      </div>
      <button className="h-9 rounded-md bg-blue px-4 uppercase text-white enabled:hover:bg-blue200 disabled:bg-gray" name="button" type="submit" disabled={Boolean(error)}>
        Sign Up
      </button>
    </form>;
};
_s(NewsletterSignup, "78uNVpw63L16kEBVNlF+vXTyX5U=");
_c = NewsletterSignup;
export default NewsletterSignup;
var _c;
$RefreshReg$(_c, "NewsletterSignup");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;