// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/CreateAccount.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/CreateAccount.tsx");
  import.meta.hot.lastModified = "1727460864852.652";
}
// REMIX HMR END

const CreateAccount = () => {
  return <form onClick={e => e.stopPropagation()} className="flex flex-col gap-3.5">
      <div className="flex flex-col gap-1">
        <label className="text-sm text-blue900" htmlFor="email">
          Email
        </label>
        <input id="email" className="h-8 rounded border border-black p-2" type="text" name="email" placeholder="Email" />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-sm text-blue900" htmlFor="password">
          Password
        </label>
        <input id="password" className="h-8 rounded border border-black p-2" type="password" name="password" placeholder="Password" />
      </div>
      <div className="flex flex-col gap-1">
        <label className="text-sm text-blue900" htmlFor="password">
          Password Confirmation
        </label>
        <input id="confirm" className="h-8 rounded border border-black p-2" type="confirm" name="confirm" placeholder="Password" />
      </div>
      <div className="flex flex-col gap-1">
        <legend className="block text-sm text-blue900">
          What Best Describes Your Use For Apparel?
        </legend>
        <div className="grid grid-cols-2 gap-1">
          <div className="flex items-center gap-1">
            <input id="business" type="radio" name="use" value="business" />
            <label className="text-sm text-blue900" htmlFor="business">
              Business
            </label>
          </div>
          <div className="flex items-center gap-1">
            <input id="personal" type="radio" name="use" value="personal" />
            <label className="text-sm text-blue900" htmlFor="personal">
              Personal
            </label>
          </div>
          <div className="flex items-center gap-1">
            <input id="school" type="radio" name="use" value="school" />
            <label className="text-sm text-blue900" htmlFor="school">
              School
            </label>
          </div>
          <div className="flex items-center gap-1">
            <input id="charity" type="radio" name="use" value="charity" />
            <label className="text-sm text-blue900" htmlFor="charity">
              Charity
            </label>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-start gap-2">
        <button className="h-8 w-auto self-start rounded-md bg-blue px-4 text-lg uppercase text-white">
          Create account
        </button>
        <p className="text-gray">
          Or{" "}
          <a className="text-sm text-blue900 underline" href="/users/sign-up">
            Sign in to your account
          </a>
        </p>
      </div>
      <p className="max-w-[50ch] self-center text-center text-gray">
        This site is protected by reCAPTCHA and the{" "}
        <a aria-label="Google‘s Privacy Policy" className="text-blue900 underline" href="https://policies.google.com/privacy">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a className="text-blue900 underline" href="https://policies.google.com/terms">
          Terms of Service
        </a>{" "}
        apply.
      </p>
    </form>;
};
_c = CreateAccount;
export default CreateAccount;
var _c;
$RefreshReg$(_c, "CreateAccount");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;