// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Modal/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Modal/index.tsx");
  import.meta.hot.lastModified = "1727460864854.0747";
}
// REMIX HMR END

const Modal = ({
  isOpen,
  setIsOpen,
  children
}) => {
  const handleCloseClick = event => {
    event.stopPropagation();
    setIsOpen(false);
  };
  if (!isOpen) return null;
  return <div role="dialog" onMouseEnter={e => e.stopPropagation()} onMouseLeave={e => e.stopPropagation()} onClick={handleCloseClick} className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-[500px] max-w-[100%] cursor-default border border-black bg-white px-7 pb-5 pt-3">
        <button onClick={handleCloseClick} className="right-0 top-0 ml-auto flex items-center gap-1 text-sm text-gray" aria-label="Close">
          <svg className="w-4 fill-gray" viewBox="0 0 21.46 19.34">
            <path d="M8.16,9.97L.6,19.04H5.14l3.32-4.23c.91-1.21,1.51-2.12,2.42-3.32h.3c.6,1.51,1.51,2.42,2.12,3.63l3.02,4.23h4.53l-7.56-9.67L21.46,0h-4.53l-3.63,4.53c-.91,1.21-1.81,2.42-2.72,3.32h-.3c-.91-.91-1.51-2.12-2.42-3.32L4.53,0H0L8.16,9.97Z" />
          </svg>
          CLOSE
        </button>
        {children}
      </div>
    </div>;
};
_c = Modal;
export default Modal;
var _c;
$RefreshReg$(_c, "Modal");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;