// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/NavItem.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/NavItem.tsx");
  import.meta.hot.lastModified = "1724095960745.5225";
}
// REMIX HMR END

import React from "react";
import useBreakpoints from "~/hooks/useBreakpoints";
export const NavLink = ({
  isActive,
  href,
  onClick,
  children
}) => {
  _s();
  const {
    isMobile
  } = useBreakpoints();
  const styles = `${isMobile && isActive ? "font-serif" : ""} flex text-2xl uppercase text-white items-center md:justify-center gap-2 md:text-sm md:normal-case md:text-blue`;
  if (isMobile && onClick) {
    return <button title="Toggle Menu" onClick={onClick} className={styles}>
        {children}
      </button>;
  }
  return <>
      <a href={href} className={styles}>
        {children}
      </a>
    </>;
};
_s(NavLink, "A/urH5Z1WsB64SjOtN+my34uVkk=", false, function () {
  return [useBreakpoints];
});
_c = NavLink;
const NavItem = ({
  children,
  isActive
}) => <li className={`${isActive ? "mb-2 block" : "hidden"} group/navItem h-10 md:relative md:flex md:h-auto md:pb-2 md:after:mx-1 md:after:text-black md:after:content-['|'] md:last:after:content-none xmd:after:mx-2`}>
    {children}
  </li>;
_c2 = NavItem;
export default NavItem;
var _c, _c2;
$RefreshReg$(_c, "NavLink");
$RefreshReg$(_c2, "NavItem");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;