// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_store.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_store.tsx");
}
// REMIX HMR END

import React from "react";
import cookie from "cookie";
import "@openfonts/source-sans-pro_all/index.css?__remix_sideEffect__";
import { json } from "@remix-run/node";
import { Outlet, useLoaderData, useLocation, useNavigate } from "@remix-run/react";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import { fetchById as fetchCartById } from "~/services/shirtspace/cart.server";
import { fetchById as fetchUserById } from "~/services/shirtspace/user.server";
import { signedRailsCookieValueFromHeaders } from "~/utils";
import { fetchSessionWithCookie } from "~/services/shirtspace/session.server";
import { fetchAllBy as fetchAllMillsBy } from "~/services/shirtspace/mills.server";
import { storefrontSession as storefrontSessionCookie } from "~/cookies.server";
import { isEmpty, omit } from "lodash";
import { isValid } from "date-fns";
import FlashMessage from "~/components/FlashMessage";
import { shouldRedirectToProxy } from "~/services/unleash/tools.server";
import CookieConsent from "~/components/CookieConsent";
import MarketingMessages from "~/components/MarketingMessages";
export const loader = async ({
  request
}) => {
  const cookieHeader = request.headers.get("Cookie");
  const cookies = cookie.parse(cookieHeader || "");
  const shirtspaceSessionCookie = cookies["_shirtspace_session"];
  const shirtspaceCurrentCartId = signedRailsCookieValueFromHeaders("current_cart_id", request.headers);
  let shirtspaceSession = null;
  if (shirtspaceSessionCookie) {
    shirtspaceSession = await fetchSessionWithCookie(shirtspaceSessionCookie);
  }
  let user = null;
  let cart = null;
  if (shirtspaceSession?.userId) {
    user = await fetchUserById(shirtspaceSession.userId);
    cart = user?.cart || null;
  } else if (shirtspaceCurrentCartId) {
    cart = await fetchCartById(shirtspaceCurrentCartId);
  }
  const unleashTest = shouldRedirectToProxy(request, user);
  if (unleashTest.needsRedirection) return unleashTest.redirect();
  const headers = new Headers();
  const expires = shirtspaceSession?.expiresAt && isValid(shirtspaceSession?.expiresAt) && shirtspaceSession.expiresAt || new Date();
  const sessionCookie = await storefrontSessionCookie.serialize(omit(shirtspaceSession, "expiresAt"), {
    expires
  });
  headers.append("Set-Cookie", sessionCookie);
  headers.append("Set-Cookie", `sessionId=${unleashTest.unleashId}`);
  const session = await storefrontSessionCookie.parse(sessionCookie);
  const mills = await fetchAllMillsBy({
    top: true,
    active: true
  });
  return json({
    cart,
    session,
    mills
  }, {
    headers
  });
};
const Store = () => {
  _s();
  const [flash, setFlash] = React.useState(null);
  const {
    cart,
    session,
    mills
  } = useLoaderData();
  const {
    state
  } = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!state?.flash) return;
    setFlash(state.flash);
    navigate(location.pathname, {
      replace: true
    });
  }, [state?.flash]);
  return <>
      <Header cart={cart} mills={mills} hasSession={!isEmpty(session)} />

      <div className="py-[6.25rem] md:py-[7.625rem]">
        <MarketingMessages />
        {flash ? <FlashMessage kind={flash.kind} message={flash.message} /> : null}
        <Outlet />
      </div>
      <CookieConsent />
      <Footer />
      <script data-testid="frontAppChat" src="https://chat-assets.frontapp.com/v1/chat.bundle.js" />

      <script data-testid="frontAppChatInitializer" dangerouslySetInnerHTML={{
      __html: `window.FrontChat('init',{chatId: '2002fad127874f2dffe3e545e3662220', useDefaultLauncher: true});`
    }} />

    </>;
};
_s(Store, "I57xP94eoG5noMzTvfJK57Cq190=", false, function () {
  return [useLoaderData, useLocation, useNavigate];
});
_c = Store;
export default Store;
var _c;
$RefreshReg$(_c, "Store");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;