// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/MarketingMessages/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/MarketingMessages/index.tsx");
  import.meta.hot.lastModified = "1728587792727.9202";
}
// REMIX HMR END

import React from "react";
const prevIndex = prevActiveIndexState => {
  if (prevActiveIndexState === 0) {
    return 5;
  }
  return prevActiveIndexState - 1;
};
const nextIndex = prevActiveIndexState => {
  if (prevActiveIndexState === 5) {
    return 0;
  }
  return prevActiveIndexState + 1;
};
const MarketingMessages = () => {
  _s();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const handleNextClick = () => {
    setActiveIndex(nextIndex);
  };
  const handlePrevClick = () => {
    setActiveIndex(prevIndex);
  };
  React.useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(nextIndex);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);
  return <div data-testid="marketingMessages" className="relative z-[150] flex w-full justify-center bg-blue900" aria-hidden="true">
      <section className="flex items-center justify-center px-3 md:mx-auto md:w-full md:max-w-[1070px] xmd:px-0">
        <div className="mx-auto flex w-full items-center text-white md:max-w-[400px]">
          <svg data-testid="prev-arrow" className="mx-auto hidden h-[16px] w-[16px] cursor-pointer fill-white opacity-[0.4] transition-[opacity_0.2s_ease-out] md:block" onClick={handlePrevClick} width="16" height="16">
            <use xlinkHref="/frontend-assets/images/icons.svg/#arrow-left" />
          </svg>
          <div className="relative flex h-[40px] w-full items-center justify-center ">
            {[<div key={0}>
                  <a className="underline" href="/shipping-policy">
                    Free Shipping Over $79
                  </a>{" "}
                  *
                </div>, <div key={1}>
                  <img className="mr-1 inline-block h-[20px] w-auto align-text-top" width="71" height="44" src="/frontend-assets/images/american-flag.png" />
                  100% U.S. Based Customer Service
                </div>, <div key={2}>No Order Minimums</div>, <div key={3}>We Accept School &amp; Agency PO&apos;s</div>, <div key={4}>Over 120 Brands &amp; Over 6,000 Products</div>, <div key={5}>
                  <a href="/reviews" className="flex items-center">
                    <svg className="mr-1 h-[20px] w-auto align-text-top" width="110">
                      <use xlinkHref="/frontend-assets/images/icons.svg/#trustpilot-stars" />
                    </svg>
                    {/*
                      /* Former implementation referenced "4.8" from
                       * db.settings:
                       * (select * from where handle = 'truspilot-rating')
                       * But given how rarely that value has changed and our
                       * pending plans to move content management out of admin,
                       * I think it's better to hardcode this and change it
                       * here if necessary
                      */}
                    <span className="underline">Rated 4.8 out of 5 Stars</span>
                  </a>
                </div>][activeIndex || 0]}
          </div>
          <svg data-testid="next-arrow" className="mx-auto hidden h-[16px] w-[16px] cursor-pointer fill-white opacity-[0.4] transition-[opacity_0.2s_ease-out] md:block" onClick={handleNextClick} width="16" height="16">
            <use xlinkHref="/frontend-assets/images/icons.svg/#arrow-right" />
          </svg>
        </div>
      </section>
    </div>;
};
_s(MarketingMessages, "16En7kR7TbAJMjBrm+xutVNIc5Q=");
_c = MarketingMessages;
export default MarketingMessages;
var _c;
$RefreshReg$(_c, "MarketingMessages");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;