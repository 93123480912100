// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/Logo.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/Logo.tsx");
  import.meta.hot.lastModified = "1724095960743.122";
}
// REMIX HMR END

const Logo = () => <div className="lg-mt-5 w-full lg:mb-10">
    <img className="mx-auto max-w-[90px]" alt="Footer logo" src="https://cdn.shirtspace.com/assets/shirtspace-badge-8368764dbd3b09e0b5d8d9693166f772739e6f3198392135c162ac7af0082449.svg" />
  </div>;
_c = Logo;
export default Logo;
var _c;
$RefreshReg$(_c, "Logo");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;