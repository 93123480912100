// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/Menu.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/Menu.tsx");
  import.meta.hot.lastModified = "1728406550620.9133";
}
// REMIX HMR END

import useBreakpoints from "~/hooks/useBreakpoints";
// TODO: Fix position at xmd
export const MenuCategories = ({
  isActive,
  children
}) => <Menu isActive={isActive}>
    <ul className="flex h-full flex-col gap-4 overflow-scroll pb-[80vh] md:block md:min-w-[38rem] md:columns-[3_191px] md:pb-0">
      {children}
    </ul>
  </Menu>;
_c = MenuCategories;
export const MenuIndustries = ({
  isActive,
  children
}) => <Menu isActive={isActive}>
    <ul className="flex h-full flex-col gap-4 overflow-scroll pb-[80vh] md:min-w-[150px] md:gap-0 md:pb-0">
      {children}
    </ul>
  </Menu>;
_c2 = MenuIndustries;
export const MenuBrands = ({
  isActive,
  children
}) => <Menu isActive={isActive}>
    <ul data-testid="brandsList" className="flex h-full w-full flex-row flex-wrap gap-y-4 overflow-scroll pb-[50vh] md:flex md:w-[41.25rem] md:flex-grow md:pb-0">
      {children}
    </ul>
  </Menu>;
_c3 = MenuBrands;
const Menu = ({
  isActive,
  children
}) => {
  _s();
  const {
    isDesktop
  } = useBreakpoints();
  return <div role="menu" aria-hidden={!isDesktop && !isActive} className={`${isDesktop || isActive ? `block` : `hidden`} mt-2 h-full md:absolute md:right-[-3rem] md:top-8 md:mt-0 md:hidden md:h-auto md:border md:border-black md:bg-white md:p-2 md:before:absolute md:before:right-[3.5rem] md:before:top-[-94px] md:before:mt-[75px] md:before:border-[12px] md:before:border-b-[6px] md:before:border-solid md:before:border-[transparent_transparent_#494949_transparent] md:before:content-[''] md:after:absolute md:after:right-[3.5rem] md:after:top-[-93px] md:after:mt-[75px] md:after:border-[12px] md:after:border-b-[6px] md:after:border-solid md:after:border-[transparent_transparent_white_transparent] md:after:content-[''] md:group-hover/navItem:block md:group-hover:flex`}>
      {children}
    </div>;
};
_s(Menu, "5NUdrMHE6FfmIwRycm/+4+A42jg=", false, function () {
  return [useBreakpoints];
});
_c4 = Menu;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "MenuCategories");
$RefreshReg$(_c2, "MenuIndustries");
$RefreshReg$(_c3, "MenuBrands");
$RefreshReg$(_c4, "Menu");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;