// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/index.tsx");
  import.meta.hot.lastModified = "1724095960743.8813";
}
// REMIX HMR END

import AddressPhone from "./AddressPhone";
import Contacts from "./Contacts";
import Bottom from "./Bottom";
import Links, { LinksNav } from "./Links";
import Logo from "./Logo";
import Middle from "./Middle";
import NewsletterSignup from "./NewsletterSignup";
import PaymentProviders from "./PaymentProviders";
import Social from "./Social";
import Top from "./Top";
const Footer = () => <footer>
    <Top>
      <PaymentProviders />
      <NewsletterSignup />
    </Top>
    <Middle>
      <Links>
        <LinksNav header="Customer Service" links={[{
        href: "/users/sign_up",
        text: "Create An Account"
      }, {
        href: "/order-status",
        text: "Track Order"
      }, {
        href: "/contact-us",
        text: "Contact Us"
      }, {
        href: "/shipping-policy",
        text: "Shipping Policy"
      }, {
        href: "/tax-policy",
        text: "Tax Policy"
      }, {
        href: "/about-us",
        text: "About Us"
      }, {
        href: "/charity",
        text: "Charities We Support"
      }, {
        href: "/faq",
        text: "FAQ"
      }, {
        href: "/glossary-of-terms",
        text: "Glossary of Terms"
      }, {
        href: "/returns",
        text: "Returns"
      }, {
        href: "/privacy-policy",
        text: "Privacy Policy"
      }, {
        href: "/privacy/do-not-sell",
        text: "Do Not Sell/Share My Information"
      }, {
        href: "/reviews",
        text: "ShirtSpace Reviews"
      }, {
        href: "/coupons",
        text: "ShirtSpace Coupons"
      }, {
        href: "/blog",
        text: "ShirtSpace Blog"
      }, {
        href: "/videos",
        text: "ShirtSpace Videos"
      }]} />
        <LinksNav header="Popular Brands" links={[{
        href: "/gildan",
        text: "Gildan"
      }, {
        href: "/port-company",
        text: "Port & Company"
      }, {
        href: "/bella-canvas",
        text: "Bella + Canvas"
      }, {
        href: "/hanes",
        text: "Hanes"
      }, {
        href: "/next-level",
        text: "Next Level"
      }, {
        href: "/comfort-colors",
        text: "Comfort Colors"
      }, {
        href: "/sport-tek",
        text: "Sport-Tek"
      }, {
        href: "/fruit-of-the-loom",
        text: "Fruit of the Loom"
      }, {
        href: "/jerzees",
        text: "Jerzees"
      }]} />
        <LinksNav header="Popular Categories" links={[{
        href: "/t-shirts",
        text: "T-Shirts"
      }, {
        href: "/sweatshirts",
        text: "Sweatshirts"
      }, {
        href: "/polos",
        text: "Polos"
      }, {
        href: "/pants",
        text: "Pants"
      }, {
        href: "/infants-toddlers",
        text: "Infants & Toddlers"
      }, {
        href: "/athletic-apparel",
        text: "Athletic Apparel"
      }, {
        href: "/tank-tops-sleeveless-shirts",
        text: "Tank Tops & Sleeveless Shirts"
      }, {
        href: "/shorts",
        text: "Shorts"
      }, {
        href: "/categories",
        text: "» See More"
      }]} />
      </Links>
      <Contacts>
        <Logo />
        <AddressPhone />
        <Social />
      </Contacts>
    </Middle>
    <Bottom />
  </footer>;
_c = Footer;
export default Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;