// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/Cart.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/Cart.tsx");
  import.meta.hot.lastModified = "1727460864852.3096";
}
// REMIX HMR END

import React from "react";
import DownArrow from "./DownArrow";
import { IconContainer, IconDropdown, IconLabel, IconSVG } from "./Icon";
import useBreakpoints from "~/hooks/useBreakpoints";
import { formatCurrency } from "~/utils";
export const DropdownContents = ({
  cart
}) => {
  const quantity = cart?.quantity || 0;
  const total = cart?.total || 0;
  const discount = cart?.discount || 0;
  const items = cart?.items || [];
  return <div className="flex flex-col items-stretch px-7 text-[#565757]">
      <div className="mb-2.5 mt-1 flex items-center justify-between text-base">
        <div>
          Total Items: <strong>{quantity}</strong>
        </div>
        <div>
          Subtotal: <strong>{formatCurrency(total - discount)}</strong>
        </div>
      </div>
      <hr />
      {items.length ? <ul className="max-h-[calc(min(40vh,250px))] w-full flex-shrink-0 overflow-y-auto p-1">
          {items.map(item => <li key={`cart-item-${item.id}`} className="flex border-b border-b-[#D6D6D6] py-2.5 last:border-b-0">
              <div className="flex items-center justify-center">
                <img className="h-[90px] w-[67px] max-w-max" src={item.image} />
              </div>
              <div className="text-[#616161]">
                <a tabIndex={0} href={item.path} className="mb-4 mt-2.5 block overflow-hidden text-ellipsis whitespace-nowrap text-blue400">
                  {item.name}
                </a>
                <span className="mb-2 inline-block w-full">
                  Price:{" "}
                  <strong className="text-[#373737]">
                    {formatCurrency(item.price)}
                  </strong>
                </span>
                <span className="mb-2 mr-2 inline-block">
                  Qty:{" "}
                  <strong className="text-[#373737]">{item.quantity}</strong>
                </span>
                <span className="mb-2 inline-block">
                  Size: <strong className="text-[#373737]">{item.size}</strong>
                </span>
                <span className="inline-block w-full">
                  Color:{" "}
                  <strong className="text-[#373737]">{item.color}</strong>
                </span>
              </div>
            </li>)}
        </ul> : <div className="py-7 text-center text-[#727779]">
          Add some items to your cart!
        </div>}
      {items.length ? <>
          <div className="mb-1 w-full self-end border-t border-t-[#D6D6D6] pt-4 text-right text-base">
            Full Price Total: {formatCurrency(total)}
          </div>
          <div className="mb-2 self-end text-base">
            Savings:{" "}
            <span className="text-red">-{formatCurrency(discount)}</span>
          </div>
        </> : null}
      <hr />
      <div className="mb-4 mt-2.5 self-end text-base">
        Subtotal: {formatCurrency(total - discount)}
      </div>
      <a href="/cart" tabIndex={0} className="h-[32px] w-[280.188px] self-center text-nowrap rounded-md bg-blue px-[15px] text-center text-[1.125rem] uppercase leading-[1.75] text-white hover:bg-blue200" type="button">
        Proceed to Secure Checkout
      </a>
    </div>;
};
_c = DropdownContents;
const Cart = ({
  cart
}) => {
  _s();
  const {
    isMobile,
    isDesktop
  } = useBreakpoints();
  const [isDropdownActive, setIsDropdownActive] = React.useState(false);
  const handleMouseLeave = () => {
    if (isMobile) return;
    setIsDropdownActive(false);
  };
  const handleMouseEnter = () => {
    if (isMobile) return;
    setIsDropdownActive(true);
  };
  const handleClick = event => {
    if (isDesktop) {
      // Using window location for now instead of useNavigate because we
      // want to force server side navigation
      window.location.href = "/cart";
      return;
    }
    event.preventDefault();
    setIsDropdownActive(!isDropdownActive);
  };
  const handleCloseClick = event => {
    event.preventDefault();
    setIsDropdownActive(false);
  };
  return <IconContainer title="Cart" href="/cart" onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <IconSVG>
        <g>
          <path d="M7.01,14.75h10.51" />
          <path d="M5.94,14.79c-.78,0-1.22-.63-.83-1.36,0-.05,.05-.05,.05-.1,.58-.78,.49-1.61,.29-2.53-.68-2.92-1.31-5.84-1.95-8.81-.05-.1-.05-.24-.1-.34H.93C.54,1.65,.2,1.51,.05,1.12-.14,.63,.2,0,.78,0h3.41c.39,0,.63,.24,.73,.58,.1,.24,.1,.54,.19,.83,.05,.1,.15,.15,.24,.19,.05,.05,.15,0,.24,0h14.6c.88,0,1.17,.29,1.17,1.17v5.94c0,.78-.29,1.02-1.07,1.12-1.65,.19-3.26,.39-4.87,.58-1.61,.15-3.21,.34-4.82,.54-1.12,.1-2.24,.24-3.41,.39,.19,.58,.29,1.12-.15,1.7h11.53c.19,0,.39,0,.58,.05,.29,.15,.54,.49,.49,.83-.05,.39-.29,.63-.68,.73-.15,.05-.29,.05-.49,.1l-12.56,.05Z" />
          <circle cx="6.48" cy="16.74" r="1.65" />
          <circle cx="18.01" cy="16.74" r="1.65" />
        </g>
      </IconSVG>
      {cart?.quantity ? <svg width="22" height="22" className={isMobile ? "absolute -right-3 -top-2" : "absolute -top-2 right-1"}>
          <circle cx="11" cy="11" r="9" fill={isMobile ? "#565757" : "#047aca"} />
          <text x="50%" y="50%" textAnchor="middle" fill="white" fontSize="10px" fontFamily="Arial" dy=".3em">
            {cart?.quantity}
          </text>
        </svg> : null}
      <IconLabel>
        Cart
        <DownArrow />
      </IconLabel>
      <IconDropdown isActive={isDropdownActive} onCloseClick={handleCloseClick}>
        <DropdownContents cart={cart} />
      </IconDropdown>
    </IconContainer>;
};
_s(Cart, "Z00cR7n0BkFDMNXu8YdM6qZi3rE=", false, function () {
  return [useBreakpoints];
});
_c2 = Cart;
export default Cart;
var _c, _c2;
$RefreshReg$(_c, "DropdownContents");
$RefreshReg$(_c2, "Cart");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;