// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/index.tsx");
  import.meta.hot.lastModified = "1728406550622.0925";
}
// REMIX HMR END

import React from "react";
import Container from "./Container";
import Hamburger from "./Hamburger";
import Logo from "./Logo";
import Search from "./Search";
import Cart from "./Cart";
import SignIn from "./SignIn";
import Nav, { NavBackControl, NavCloseControl, NavControls } from "./Nav";
import NavItem, { NavLink } from "./NavItem";
import DownArrow from "./DownArrow";
import { MenuBrands, MenuCategories, MenuIndustries } from "./Menu";
import MenuItem from "./MenuItem";
import { MenuBrandLogo, MenuBrandItem, MenuBrandLink, MenuBrandViewAll, MenuLink } from "./MenuItem";
const Header = ({
  cart,
  hasSession,
  mills
}) => {
  _s();
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const [menu, setMenu] = React.useState("all");
  return <Container>
      <Hamburger isActive={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)} />
      <Logo />
      <Search />
      <SignIn hasSession={hasSession} />
      <Cart cart={cart} />
      <Nav isActive={mobileNavOpen}>
        <NavControls>
          {menu !== "all" && <NavBackControl onClick={() => setMenu("all")} />}
          <NavCloseControl onClick={() => setMobileNavOpen(false)} />
        </NavControls>
        {[{
        href: "/t-shirts",
        label: "T-Shirts"
      }, {
        href: "/sweatshirts",
        label: "Sweatshirts"
      }, {
        href: "/tank-tops-sleeveless-shirts",
        label: "Tank Tops"
      }, {
        href: "/t-shirts/mens",
        label: "Men"
      }, {
        href: "/t-shirts/womens",
        label: "Women"
      }, {
        href: "/t-shirts/unisex",
        label: "Unisex"
      }, {
        href: "/t-shirts/kids",
        label: "Kids"
      }].map(({
        href,
        label
      }) => <NavItem key={href} isActive={menu === "all"}>
            <NavLink href={href}>{label}</NavLink>
          </NavItem>)}
        <NavItem isActive={["categories", "all"].includes(menu)}>
          <NavLink href="/categories" onClick={() => setMenu("categories")} isActive={menu === "categories"}>
            Category
            <DownArrow />
          </NavLink>
          <MenuCategories isActive={menu === "categories"}>
            {[{
            href: "/accessories",
            label: "Accessories"
          }, {
            href: "/athletic-apparel",
            label: "Athletic Apparel"
          }, {
            href: "/bags",
            label: "Bags"
          }, {
            href: "/face-masks-face-coverings",
            label: "Face Masks & Face Coverings"
          }, {
            href: "/hats-beanies",
            label: "Hats & Beanies"
          }, {
            href: "/heat-transfer-vinyl",
            label: "Heat Transfer Vinyl"
          }, {
            href: "/infants-toddlers",
            label: "Infants & Toddlers"
          }, {
            href: "/jackets-coats",
            label: "Jackets & Coats"
          }, {
            href: "/office-apparel",
            label: "Office Apparel"
          }, {
            href: "/pants",
            label: "Pants"
          }, {
            href: "/polos",
            label: "Polos"
          }, {
            href: "/shorts",
            label: "Shorts"
          }, {
            href: "/sweaters-cardigans",
            label: "Sweaters & Cardigans"
          }, {
            href: "/sweatshirts",
            label: "Sweatshirts"
          }, {
            href: "/tank-tops-sleeveless-shirts",
            label: "Tank Tops & Sleeveless Shirts"
          }, {
            href: "/t-shirts",
            label: "T-Shirts"
          }, {
            href: "/underwear",
            label: "Underwear"
          }, {
            href: "/work-shirts",
            label: "Work Shirts"
          }, {
            href: "/new-arrivals",
            label: "New Arrivals"
          }, {
            href: "/closeout",
            label: "Closeout"
          }].map(({
            href,
            label
          }) => <MenuItem key={href}>
                <MenuLink href={href}>{label}</MenuLink>
              </MenuItem>)}
          </MenuCategories>
        </NavItem>
        <NavItem isActive={["brands", "all"].includes(menu)}>
          <NavLink href="/brands" onClick={() => setMenu("brands")} isActive={menu === "brands"}>
            Brand
            <DownArrow />
          </NavLink>
          {mills ? <MenuBrands isActive={menu === "brands"}>
              {mills?.map(({
            name,
            logo,
            path
          }) => <MenuBrandItem key={name}>
                  <MenuBrandLink href={path}>
                    <MenuBrandLogo src={logo} alt={name} />
                  </MenuBrandLink>
                </MenuBrandItem>)}
              <MenuBrandViewAll />
            </MenuBrands> : null}
        </NavItem>
        <NavItem isActive={["industries", "all"].includes(menu)}>
          <NavLink href="/industries" onClick={() => setMenu("industries")} isActive={menu === "industries"}>
            Industry
            <DownArrow />
          </NavLink>
          <MenuIndustries isActive={menu === "industries"}>
            {[{
            href: "/direct-to-garment",
            label: "DTG Direct To Garment"
          }, {
            href: "/tie-dye-clothing",
            label: "Tie-Dye Clothing"
          }, {
            href: "/sublimation",
            label: "Sublimation"
          }, {
            href: "/screen-printing",
            label: "Screen Printing"
          }, {
            href: "/htv-heat-transfer-vinyl",
            label: "HTV Heat Transfer Vinyl"
          }, {
            href: "/embroidery",
            label: "Embroidery"
          }, {
            href: "/bleaching",
            label: "Bleaching"
          }].map(({
            href,
            label
          }) => <MenuItem key={href}>
                <MenuLink href={href}>{label}</MenuLink>
              </MenuItem>)}
          </MenuIndustries>
        </NavItem>
        <NavItem isActive={menu === "all"}>
          <NavLink href="/sales">
            <span className="text-red500">SALE</span>
          </NavLink>
        </NavItem>
        <NavItem isActive={menu === "all"}>
          <NavLink href="/under-5">UNDER $5</NavLink>
        </NavItem>
      </Nav>
    </Container>;
};
_s(Header, "5w1PYB3V7AjruTZX2jQxzmIwbNQ=");
_c = Header;
export default Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;