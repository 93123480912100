// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/Logo.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/Logo.tsx");
  import.meta.hot.lastModified = "1727460864853.0566";
}
// REMIX HMR END

import useBreakpoints from "~/hooks/useBreakpoints";
const Logo = () => {
  _s();
  const {
    isDesktop
  } = useBreakpoints();
  return <a aria-label="Home" href="/" className="block h-[29px] w-[150px] md:h-[38px] md:w-[200px]">
      <img className="md:block" src={`/frontend-assets/images/logo_${isDesktop ? "desktop" : "mobile"}.svg`} alt="ShirtSpace.com Logo" />
    </a>;
};
_s(Logo, "5NUdrMHE6FfmIwRycm/+4+A42jg=", false, function () {
  return [useBreakpoints];
});
_c = Logo;
export default Logo;
var _c;
$RefreshReg$(_c, "Logo");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;