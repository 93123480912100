// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Footer/Middle.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Footer/Middle.tsx");
  import.meta.hot.lastModified = "1724095960743.2334";
}
// REMIX HMR END

const Middle = ({
  children
}) => <div className="border-t-6 border-blue bg-blue100 py-11">
    <div className="items-between mx-auto flex max-w-[1070px] flex-wrap gap-y-5 lg:gap-x-20">
      {children}
    </div>
  </div>;
_c = Middle;
export default Middle;
var _c;
$RefreshReg$(_c, "Middle");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;