// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/FlashMessage/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/FlashMessage/index.tsx");
}
// REMIX HMR END

import React from "react";
import "./styles.css?__remix_sideEffect__";
const errorIcon = `url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmY0ZTQ0O30uYntmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7c3Ryb2tlLXdpZHRoOjNweDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPldhcm5pbmcgSWNvbjwvdGl0bGU+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMS4zMiwxLjJjLS43Mi0xLjQ4LTEuOTEtMS40OC0yLjYzLDBMLjgyLDE3LjNBMS43MywxLjczLDAsMCwwLDIuNSwyMGgxNWExLjczLDEuNzMsMCwwLDAsMS42OC0yLjdaIi8+PGxpbmUgY2xhc3M9ImIiIHgxPSIxMCIgeTE9IjYiIHgyPSIxMCIgeTI9IjExIi8+PGxpbmUgY2xhc3M9ImIiIHgxPSIxMCIgeTE9IjE3IiB4Mj0iMTAiIHkyPSIxNy4xIi8+PC9zdmc+)`;
const successIcon = `url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojMjA5NjA0O30uYntmaWxsOm5vbmU7c3Ryb2tlOiNmZmY7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7c3Ryb2tlLXdpZHRoOjNweDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPkV4Y2xhbWF0aW9uIE1hcmsgY29weTwvdGl0bGU+PHBhdGggY2xhc3M9ImEiIGQ9Ik0xMCwxOWE5LDksMCwxLDEsOS05QTksOSwwLDAsMSwxMCwxOVoiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTEwLDJhOCw4LDAsMSwxLTgsOCw4LDgsMCwwLDEsOC04bTAtMkExMCwxMCwwLDEsMCwyMCwxMCwxMCwxMCwwLDAsMCwxMCwwWiIvPjxsaW5lIGNsYXNzPSJiIiB4MT0iMTAiIHkxPSI0LjUiIHgyPSIxMCIgeTI9IjkuNSIvPjxsaW5lIGNsYXNzPSJiIiB4MT0iMTAiIHkxPSIxNS41IiB4Mj0iMTAiIHkyPSIxNS42Ii8+PC9zdmc+)`;
const FlashMessage = ({
  kind,
  message
}) => {
  return <div role="alert" data-type={kind} style={{
    backgroundColor: {
      failure: "#ffe6e6",
      success: "#e6ffe6"
    }[kind]
  }} className="mx-auto w-full max-w-[800px] self-center rounded-lg px-5 py-3 shadow-sm">

      <p style={{
      backgroundImage: {
        failure: errorIcon,
        success: successIcon
      }[kind],
      color: {
        failure: "#cc0000",
        success: "#0d800d"
      }[kind]
    }} className="bg-left-center bg-no-repeat pl-8 text-sm">

        {message}
      </p>
    </div>;
};
_c = FlashMessage;
export default FlashMessage;
var _c;
$RefreshReg$(_c, "FlashMessage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;