// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/CookieConsent/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/CookieConsent/index.tsx");
  import.meta.hot.lastModified = "1728679226544.7644";
}
// REMIX HMR END

import React from "react";
function getCookie(name) {
  if (typeof document === "undefined") return null;
  try {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
  } catch (error) {
    console.error(error);
  }
}
const CookieConsent = () => {
  _s();
  const [isVisible, setIsVisible] = React.useState(false);
  React.useEffect(() => {
    const cookie = getCookie("jsCookieConsentGiven");
    if (cookie) return;
    setIsVisible(true);
  }, []);
  const handleCloseClick = () => {
    setIsVisible(false);
    document.cookie = "jsCookieConsentGiven=1;";
  };
  if (!isVisible) return null;
  return <div data-testid="cookie-consent" className="fixed bottom-0 left-0 z-[9999999999] flex w-full justify-between bg-blue px-5 py-2.5 pb-4 font-normal text-white shadow-[0_0_15px_-5px_rgba(0,0,0,0.4)]" role="dialog" aria-describedby="sticky-notification" aria-label="Cookie Consent">
      <div className="text-sm">
        <p className="max-w-[155ch] leading-6">
          We use cookies to offer you a better experience, analyze site traffic,
          and serve targeted advertisements. By continuing to use this website,
          you consent to the use of cookies in accordance with our{" "}
          <a className="font-bold underline" href="/privacy-policy#cookie_policy">
            Cookie Policy
          </a>
          .
        </p>
      </div>
      <div className="sticky-notification__command">
        <a onClick={handleCloseClick} className="m-1.5 cursor-pointer py-0 text-xl font-medium" role="button" aria-label="Close">
          [&nbsp;x&nbsp;]
        </a>
      </div>
    </div>;
};
_s(CookieConsent, "J3yJOyGdBT4L7hs1p1XQYVGMdrY=");
_c = CookieConsent;
export default CookieConsent;
var _c;
$RefreshReg$(_c, "CookieConsent");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;