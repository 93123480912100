// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Header/Container.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Header/Container.tsx");
  import.meta.hot.lastModified = "1727460864852.446";
}
// REMIX HMR END

const Container = ({
  children
}) => <header className="fixed z-[1000] w-full bg-blue shadow-[0_4px_5px_-1px_rgba(0,0,0,0.2)] md:left-0 md:right-0 md:h-auto md:bg-white">
    <div className="flex flex-wrap items-center gap-x-2 gap-y-3 px-3 py-3 md:mx-auto md:mt-[1.6rem] md:w-[100%] md:max-w-[1070px] md:items-start md:gap-x-[0.625rem] md:gap-y-1 md:pb-0 md:pt-0 xmd:px-0">
      {children}
    </div>
  </header>;
_c = Container;
export default Container;
var _c;
$RefreshReg$(_c, "Container");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;