// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils.ts"
);
import.meta.hot.lastModified = "1727895046526.208";
}
// REMIX HMR END

import cookie from "cookie";

/*
 * Extracts value with key from a cookie signed by rails
 * see: https://dev.to/ayushn21/demystifying-cookie-security-in-rails-6-1j2f
 *
 * @param headers - The headers object from a request
 * @returns The cart id or null
 */
export function signedRailsCookieValueFromHeaders(
  key: string,
  headers: Headers,
) {
  const cookies = cookie.parse(headers.get("Cookie") || "");
  const signedCookie = cookies[key];

  if (!signedCookie) return null;

  const [encodedPayload, digest] = signedCookie.split("--");

  if (!digest) {
    console.error("Cookie is malformed");
    return null;
  }

  let parsedPayload: { _rails: { message: string } } | null = null;

  try {
    const uriDecodedPayload = decodeURIComponent(encodedPayload);
    const base64DecodedPayload = Buffer.from(
      uriDecodedPayload,
      "base64",
    ).toString("utf-8");
    parsedPayload = JSON.parse(base64DecodedPayload);
  } catch (error) {
    console.error("Error parsing cookie payload", error);
    return null;
  }

  try {
    if (!parsedPayload?._rails?.message) {
      throw new Error("Cookie value malformed");
    }

    const encodedValue = parsedPayload._rails.message;
    const uriDecodedValue = decodeURIComponent(encodedValue);
    const base64DecodedValue = Buffer.from(uriDecodedValue, "base64").toString(
      "utf-8",
    );

    return JSON.parse(base64DecodedValue);
  } catch (error) {
    console.error("Error parsing cookie value", error);
    return null;
  }
}

export function serializeUnknownError(error: unknown) {
  return JSON.stringify(error, Object.getOwnPropertyNames(error));
}

export function formatCurrency(
  amount: number,
  options?: Intl.NumberFormatOptions,
) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...options,
  }).format(amount);
}

export function formatPercent(amount: number) {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(amount);
}
